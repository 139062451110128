<template lang="pug">
  .form-group
    label(:for="idComplete" v-if="label") {{ label }}:
    input.form-control(
      v-model.trim="model.$model"
      :class="classes"
      :id="idComplete"
      :type="type"
      :disabled="!stateEnabled"
      :placeholder="statePlaceholder"
      @input="model.$reset()"
      @blur="model.$touch()"
    )
    form-input-error-message(:field="model")
</template>

<script>
import FormInputErrorMessage from "@/components/common/FormInputErrorMessage";

export default {
  components: {
    FormInputErrorMessage
  },
  props: {
    model: {
      type: Object,
      default: () => {}
    },
    type: {
      type: String,
      default: 'text'
    },
    id: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: ''
    },
    help: {
      type: String,
      default: null
    },
    enabled: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      stateEnabled: this.enabled,
      statePlaceholder: this.placeholder
    }
  },

  methods: {
    loading(placeholder = '') {
      // Copy original placeholder
      this.originalPlaceholder = this.statePlaceholder;
      this.statePlaceholder = placeholder;

      // Change state
      this.stateEnabled = false;
    },
    loaded() {
      this.statePlaceholder = this.originalPlaceholder;
      this.stateEnabled = true;
    }
  },

  computed: {
    idComplete() {
      return `${this.id}`;
    },

    classes() {
      const classes = [];

      if(this.model.$dirty) {
        if(this.model.$error) {
          classes.push('is-invalid');
        } else {
          classes.push('is-valid');
        }
      }

      return classes;
    }
  }
}
</script>
