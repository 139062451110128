<template lang="pug">
  .card
    .card-body.login-card-body
      p.login-box-msg(v-html="$t('presentation')")

      .alert.alert-danger(role="alert" v-if="error") {{ $t('form.error.generic') }}

      form(@submit.prevent="login()")
        form-input-group.mb-3(
          type="email"
          id="username"
          :model="$v.username"
          :placeholder="$t('form.email')"
          :enabled="!submitting"
        )
          template(v-slot:append-text)
            .input-group-text
              fa(icon="envelope")

        form-input-group.mb-3(
          type="password"
          id="password"
          :model="$v.password"
          :placeholder="$t('form.password')"
          :enabled="!submitting"
        )
          template(v-slot:append-text)
            .input-group-text
              fa(icon="lock")

        .row
          .col-7
            //.form-check
            //  input.form-check-input#remember(type='checkbox')
            //  label.form-check-label(for='remember')
            //    | {{ $t('form.remember') }}

          .col-5
            button.btn.btn-primary.btn-block(type="submit" :disabled="submitting")
              span(v-if="!submitting") {{ $t('form.signIn') }}
              button-spinner(v-else color="#FFFFFF")
</template>

<script>
import { required, email } from 'vuelidate/lib/validators';
import auth from '@/auth';
import FormInputGroup from "@/components/common/FormInputGroup";

export default {
  components: {
    FormInputGroup
  },

  data() {
    return {
      submitting: false,
      error: false,
      username: null,
      password: null
    }
  },

  validations: {
    username: {
      required,
      email
    },
    password: {
      required
    }
  },

  methods: {
    login() {
      this.submitting = true;

      auth
        .login(this.username, this.password)
        .then(() => this.$router.push({ name: 'sessions_home' }))
        .catch((exception) => this.registerError(exception))
        .finally(() => this.submitting = false)
    },

    registerError() {
      this.error = true;
    }
  }
}
</script>

<i18n>
{
  "es": {
    "presentation": "Escribe tu usuario y contraseña para iniciar sesión en <b>Bienesmart</b>",
    "form": {
      "error": {
        "generic": "El usuario o contraseña es incorrecto. Por favor, vuélvalo a intentar."
      },
      "email": "Correo electrónico",
      "password": "Contraseña",
      "remember": "Recordar",
      "signIn": "Iniciar sesión"
    }
  }
}
</i18n>
