<i18n>
    {
        "es": {
            "minLength": "Debe tener más de {chars} caracteres.",
            "maxLength": "Debe tener menos de {chars} caracteres.",
            "required": "Este campo es obligatorio.",
            "alpha": "Sólo puede contener letras.",
            "alphaNum": "Sólo letras y números. NO símbolos raros (#$%@.).",
            "email": "El correo electrónico no es válido.",
            "uppercase": "Debe tener al menos {num} letra mayúscula.",
            "lowercase": "Debe tener al menos {num} letra minúscula.",
            "digit": "Debe tener al menos {num} número.",
            "between": "Debe tener un valor de {min} a {max}"
        }
    }
</i18n>

<template lang="pug">
    .invalid-feedback(v-if="field.$dirty && field.$error")
        span(v-if="false === field.minLength" v-html="$t('minLength', { chars: field.$params.minLength.min })")
        span(v-if="false === field.maxLength" v-html="$t('maxLength', { chars: field.$params.maxLength.max })")
        span(v-if="false === field.uppercase" v-html="$t('uppercase', { num: field.$params.uppercase.num })")
        span(v-if="false === field.lowercase" v-html="$t('lowercase', { num: field.$params.lowercase.num })")
        span(v-if="false === field.digit" v-html="$t('digit', { num: field.$params.digit.num })")
        span(v-if="false === field.required" v-html="$t('required')")
        span(v-if="false === field.alpha" v-html="$t('alpha')")
        span(v-if="false === field.alphaNum" v-html="$t('alphaNum')")
        span(v-if="false === field.email" v-html="$t('email')")
        span(v-if="false === field.between" v-html="$t('between', { min: field.$params.between.min, max: field.$params.between.max })")
</template>

<script>
    export default {
        props: {
            field: {
                type: Object,
                default: () => {}
            },
            defaultStyle: {
                type: Boolean,
                default: true
            }
        }
    }
</script>
